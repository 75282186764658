<template>

<div class="card">

	<div class="card-header">
		<h3 class="card-title">
			{{msg}}
		</h3>
	</div>

	<labelme></labelme>

  </div>
</template>

<script>
import Labelme from '../Labelme/Labelme.vue'

export default {
  name: 'Annotations',
  data () {
    return {
      msg: '数据标注'
    }
  },
  components: {
    Labelme
  }

}
</script>

<style scoped>

</style>

